import config from './config';
import logger from './logger';

type Importmap = {
  imports: Record<string, string>;
};

type VerifyNewRelease = () => Promise<boolean>;

type AbaReleaseDetector = {
  verifyNewRelease: VerifyNewRelease;
  appendToWindow: () => void;
};

declare global {
  interface Window {
    ABA_IMPORTMAP: Importmap;
    abaReleaseDetector: AbaReleaseDetector;
  }
}

const WARNING_MESSAGE = 'An updated version of the application is ready. Reloading!';

const getImportmapUrl = (importmapPath: string): string => `${importmapPath}?t=${Date.now()}`;

const fetchImportmap = (importmapPath: string): Promise<any> =>
  fetch(importmapPath)
    .then((response) => response.json())
    .catch(logger.error);

const hasAnyNewMFEVersion = (importmap: Importmap, newImportmap: Importmap): boolean => {
  const newImportmapKeys = Object.keys(newImportmap.imports);

  return newImportmapKeys.some((key) => newImportmap.imports[key] !== importmap.imports[key]);
};

const reloadBrowser = (message: string) => {
  logger.warn(message);
  window.location.reload();
};

const verifyNewRelease: VerifyNewRelease = async () => {
  const importmapUrl = getImportmapUrl(config.importmapUrl);
  const importmap = await fetchImportmap(importmapUrl);

  if (!hasAnyNewMFEVersion(window.ABA_IMPORTMAP, importmap)) {
    return false;
  }

  reloadBrowser(WARNING_MESSAGE);

  return true;
};

const releaseDetector: AbaReleaseDetector = {
  verifyNewRelease,
  appendToWindow() {
    window.abaReleaseDetector = this;
  },
};

export default releaseDetector;

export type { Importmap };
