type Logger = {
  error: (message?: any, ...optionalParams: any[]) => void;
  warn: (message?: any, ...optionalParams: any[]) => void;
};

const createLogger = (logger: Console): Logger => ({
  error: (...args: any[]) => logger.error(...args),
  warn: (...args: any[]) => logger.warn(...args),
});

export default createLogger;

export type { Logger };
