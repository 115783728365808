// @ts-ignore @abatech/api is a utility package that is not published to npm
import { initializeGA } from '@abatech/api';
import { addErrorHandler, getAppStatus, LOAD_ERROR, registerApplication, start } from 'single-spa';
import { constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout';

import releaseDetector from './infrastructure/releaseDetector';

initializeGA();

const routes = constructRoutes(document.querySelector('#single-spa-layout'), {
  loaders: {},
  props: {},
  errors: {
    error: "<p>Something went wrong. Can't load this module.</p>",
  },
});

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

layoutEngine.activate();

addErrorHandler((err) => {
  if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
    releaseDetector.verifyNewRelease();
  }
});

releaseDetector.appendToWindow();

start();
